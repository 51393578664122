@import "sass-lib";
@media (min-width: 1480px) and (max-width: 1680px) {
	.three-box.newshome figure{ height: auto !important;}
	.home.slider-direksi-qts{ padding-top: 0px !important;}
	.slider-gallery .desc{ bottom: 25px !important;}
	.home.newsevent{ padding: 105px 0 18px !important; }
}
@media (min-width: 835px) and (max-width: 1590px) {
	.home{
		&.newsevent{ padding: 225px 0px 130px !important; }
	}
}
@media (min-width: 0px) and (max-width: 1680px) {
	.home{
		&.slide-wave{
			.desc{
				top: 30%;
			}
		}
		.form-middle{
			.row{
				margin-bottom: 20px;
			}
		}
	}
	.wrapper{ 
		width:auto; margin: 0 140px;  
		&.v2{ padding: 0; }
	}	
	.two-box{
		.rh{ width: 450px; padding-left: 50px;	 }
		&.project{
			.images{
				@include transform(scale(.6)); transform-origin: 0 0; 
			}
		}
	}
	.video-middle{
		&.home{ 
			margin: 0 ; 
			.thumb-video{ height: auto; }
		}

	}
	.home-slide-section{

		.slider-home{
			.slide-banner{
		
				.caption-text{
					.txt{
						h2{ font-size: 42px; }
					}
				}
				.slick-dots{ bottom: 15%; }
			}
		}
	}
	.pagedown{ bottom: 2%; }
	.home{
		&.about{
			padding: 0px 0 40px;
			p{ margin-bottom: 40px; }
			.wrapper{ position: relative; top: 55%; @include transform(translateY(-50%)); }
		}
		&.slider-direksi-qts{
			.wrapper{ width: 100%; padding: 0px 140px; @include boxSizing(border-box); margin: auto;  }
			.text-qoutes{
				P{ font-size: 22px; }
			}
			.slider-direksi{
				padding: 0 60px;
			}
		}
		&.newsevent{
			padding: 145px 0 75px; 
			&.scrollify{ height: auto; }
			.ttl{ font-size: 24px; line-height: 30px; }
		}
		&.slide-wave{
			.more{ margin-top: 10px; }
		}
		&.form-newsevent{
			&.scrollify{ height: calc(100vh); }
			.wrapper{ 
				width: 100%; padding: 0px 140px; @include boxSizing(border-box); margin: auto;  
				.tittle{ margin-top: 20px;}
			}
			.form-middle{
				margin: 15px 0 0px;
			}
		}

	}
	.three-box{
		figure{ height: 300px; }
		.row-left-right{
			.left{ padding-top: 5px; }
		}
		h4{ font-size: 18px; line-height: 28px; margin-bottom: 0; }
		&.newshome{
			figure{ height: 200px; }
			p{ display: none; }
		}
		.text{ padding: 15px 20px 15px; }
	}
	.video-middle{
		.thumb-video{
			height: auto;
			img{ height: 100%; object-fit: cover; }
			
		}
	}
	.wrap-dot-bg{ bottom: 20px; }
	.two-box{
		.lf,.rh{ vertical-align: top; }
	}
	.middle{
		h2{ font-size: 32px; }
	}
	.slider-gallery{
		position: relative;
		.desc{ 
			bottom: 20px;
			h4{ line-height: 30px; }
		}

	}
	.sec-box-two{
		.left-box,.right-box{ padding: 70px 0; }
		.left-box{ padding-right: 55px;  }
		.right-box{ padding-left: 55px;  }
	}
	.wrap-download{ padding: 5px 25px 5px 5px; }
	.wrap-left-right{ margin-top: 70px; }

	.tabbing-spek{
		.btn-tab{ margin: 0 auto; right: 0; position: relative;}
		.slide-type{
			margin-top: 0; width: 45%; 
			 .border-bg{ width: 80%; height: 460px; }
		}
		.box-desc-type{ width: 40%; padding-top:55px;   }
		.slider-small{
			padding: 0 80px;
			.list{ margin-right: 30px; }
			figure{
				padding: 10px 0;
			}
		}
		.slider-big{
			padding: 0 55px;
		}

	}
	.form-middle{ padding: 0; }
	.masterplan{
		.img-plan{ 
			height: 350px; 
			img{ width: 100%; height: 100%; object-fit: cover; }
		}
	}
	footer{
		.top{
			.box{
				margin-right: 80px;
			}
		}
	}
}


@media (min-width: 0px) and (max-width: 1280px) {

	.slider-address{ padding: 0 100px; }
	.middle{
		h2{ font-size: 30px; line-height: 40px; }
	}
}